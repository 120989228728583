.container {
  padding: 0 2rem;
}

.main {
  min-height: 100vh;
  padding: 4rem 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer {
  display: flex;
  flex: 1;
  padding: 2rem 0;
  border-top: 1px solid #eaeaea;
  justify-content: center;
  align-items: center;
}

.footer a {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.title a {
  color: #0070f3;
  text-decoration: none;
}

.title a:hover,
.title a:focus,
.title a:active {
  text-decoration: underline;
}

.title {
  margin: 0;
  line-height: 1.15;
  font-size: 4rem;
}

.title,
.description {
  text-align: center;
}

.description {
  margin: 4rem 0;
  line-height: 1.5;
  font-size: 1.5rem;
}

.code {
  background: #fafafa;
  border-radius: 5px;
  padding: 0.75rem;
  font-size: 1.1rem;
  font-family: Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono,
    Bitstream Vera Sans Mono, Courier New, monospace;
}

.grid {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 800px;
}

.card {
  margin: 1rem;
  padding: 1.5rem;
  text-align: left;
  color: inherit;
  text-decoration: none;
  border: 1px solid #eaeaea;
  border-radius: 10px;
  transition: color 0.15s ease, border-color 0.15s ease;
  max-width: 300px;
}

.card:hover,
.card:focus,
.card:active {
  color: #0070f3;
  border-color: #0070f3;
}

.card h2 {
  margin: 0 0 1rem 0;
  font-size: 1.5rem;
}

.card p {
  margin: 0;
  font-size: 1.25rem;
  line-height: 1.5;
}

.logo {
  height: 1em;
  margin-left: 0.5rem;
}
.content_bg {
  background-image: url('../public/bubbles.jpg');
  height: 75vh;
  background-size: cover;
  background-repeat: no-repeat;
  width: '100%';
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}
.title {
  font-size: 2.6rem;
  line-height: 3.75rem;
  text-align: center;
  font-weight: 700;
}
.para1 {
  font-size: 1.5rem;
  text-align: center;
  font-weight: 400;
  margin: 5px;
  margin-top: 20px;
}
.para {
  font-size: 1.5rem;
  text-align: center;
  font-weight: 400;
  margin: 5px;
}
.row__title {
  font-size: 3em;
  color: #35bfff;
  text-align: center;
  margin-bottom: 40px;
  font-weight: 300;
  display: block;
  line-height: 1.37rem;
}
.textpara {
  color: #999;
  font-size: 1.5em;
  line-height: 1.2em;
  font-weight: 300;
  text-align: center;
  padding: 0 20px;
}
.para2 {
  display: none;
}
@media (max-width: 600px) {
  .grid {
    width: 100%;
    flex-direction: column;
  }
  .title {
    font-size: 1.8rem;
    line-height: 2.4rem;
  }
  .para1 {
    display: none;
  }
  .para {
    display: none;
  }
  .para2 {
    display: block;
    text-align: center;
    font-size: 1.2rem;
    line-height: 1.5rem;
    padding: 40px;
    padding-top: 0;
  }
  .content_bg {
    height: 70vh;
  }
}